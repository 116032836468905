import axios, { Axios } from "axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {
  lang: [],
};

export const getLanguages = createAsyncThunk("getLanguages", async () => {
  const response = await axios.get("/languages.json?v=1");

  return response.data;
});

const authorizationToken = createSlice({
  name: "autToken",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getLanguages.fulfilled, (state, action) => {
        // işlem başarılı olduğunda yapılacak işlemler
        state.lang = action.payload;
      })
      .addCase(getLanguages.pending, (state) => {
        // işlem başladığında yapılacak işlemler
        //state.userInfo = {};
      })
      .addCase(getLanguages.rejected, (state) => {
        // işlem hatalı olursa yapılacak işlemler
        //state.userInfo = {};
      });
  },
});

export const { lang } = authorizationToken.actions;

export default authorizationToken.reducer;
